<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <TableSlug :slugs="slugs" @refresh="refresh" @editForm="editForm" />
    </div>
    <modalCreateSlug @refresh="refresh" />
    <modalEditSlug :id="id" @refresh="refresh" />
  </div>
</template>

<script>
import TableSlug from "@/views/component/Table/tableSlug.vue";
import modalCreateSlug from "@/views/component/Modal/ModalSlugs/modalCreateSlug.vue";
import modalEditSlug from "@/views/component/Modal/ModalSlugs/modalEditSlug.vue";
export default {
  components: {
    TableSlug,
    modalCreateSlug,
    modalEditSlug,
  },
  data() {
    return {
      showPreloader: false,
      slugs: [],
      id: null,
      editSlugSet: null,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      let params = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      this.showPreloader = true;
      this.$http.get(`${this.$route.name}`, { params: params }).then((res) => {
        this.slugs = res.data.slags;
        this.showPreloader = false;
        this.$store.commit("pageData/setdataCount", this.slugs.length);
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    editForm(id) {
      this.id = id;
      this.editSlugSet = setTimeout(() => {
        this.$bvModal.show("editSlug");
      }, 0);
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData() {
      this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped></style>
